import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import React, { useState, useEffect } from "react";
import { Trophy } from "@phosphor-icons/react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Accordion from "../components/Accordion";
import { getUserStats } from "../../api/users";
import { UserStats } from "../../models/UserStats";

import UserBadgesLargeDisplay from "../components/UserBadgesLargeDisplay";
import { useBadges } from "../../providers/BadgesProvider";

const MyDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { authState } = useAuth();
  const { badges } = useBadges();
  const [userStats, setUserStats] = useState<UserStats | null>(null);
  const [showCompleteProfile, setShowCompleteProfile] = useState(false);

  useEffect(() => {
    fetchUserStats();
  }, [authState.user]);

  //TODO refactor into a hook as AuthProvider
  const fetchUserStats = async () => {
    if (authState.user) {
      try {
        const stats = await getUserStats(authState.user?.id);
        setUserStats(stats);
        var showProfile =
          stats.badges.filter(
            (badge) => badge.name === "GBC Member" && badge.obtained === false
          ).length > 0;
        setShowCompleteProfile(showProfile);
      } catch (error) {
        new Error("Failed to fetch user stats");
      }
    }
  };

  // const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScroll(window.scrollY > 275);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   // Cleanup event listener on component unmount
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const onProfileClick = useCallback(() => {
    navigate("/my-profile");
  }, [navigate]);

  // Define the accordion items
  const accordionItems = [
    {
      id: 2,
      title: "Badges",
      content: (
        <>
          <div className="row">
            <UserBadgesLargeDisplay
              userBadges={userStats?.badges}
              publicProfile={false}
              allBadges={badges}
            />
          </div>
        </>
      ),
    },
    {
      id: 3,
      title: "Points",
      content: (
        <>
          <Tabs>
            <TabList>
              <Tab>How to get Points</Tab>
              <Tab>Where to use Points</Tab>
            </TabList>

            <TabPanel>
              <div className="bg-dark-light w-100">
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Create account</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>200 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Subscription payment</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>200 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Complete profile</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>50 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Mark lesson as learned</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>15 XP</small>
                  </p>
                </div>
                <p className="fw-semibold mt-4 mb-0 fs-6">Daily login</p>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>First login of the series</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>5 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Login on Days 2, 3, 4 of the series</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>10 XP/Day</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Daily login after Day 5</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>20 XP/Day</small>
                  </p>
                </div>
                <p className="fw-semibold mt-4 mb-0 fs-6">Upload video</p>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Rhythm</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>30 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Intonation</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>25 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Originality</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>20 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between mb-2">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Improvisation</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>20 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Accept challenge</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>50 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Participate in battle</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>100 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Win a battle</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>300 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Win a tournament</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>5000 XP</small>
                  </p>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bg-dark-light w-100">
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Challenge to battle</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>50 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>Register for a tournament</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>2000 XP</small>
                  </p>
                </div>
                <div className="d-flex w-100 flex-wrap justify-content-between">
                  <p className="text-secondary text-truncate mb-0">
                    <small>30-minute concert</small>
                  </p>
                  <p className="mb-0 fw-semibold">
                    <small>15000 XP</small>
                  </p>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </>
      ),
    },
    {
      id: 1,
      title: "Tournaments",
      content: (
        <>
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
                <p className="mb-0 text-secondary">
                  <small>Date</small>
                </p>
                <p className="mb-0 fw-semibold">November 8, 2024</p>
              </div>
              <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
                <p className="mb-0 text-secondary">
                  <small>Entry Fee</small>
                </p>
                <p className="mb-0 fw-semibold">2000 Points</p>
              </div>
            </div>
            <div className="mt-3">
              <p className="fw-normal">
                <small>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying on meaningful content.
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </small>
              </p>
            </div>
            <button className="text-warning bg-transparent fw-normal border-0 p-0 mb-1 d-flex gap-2 align-items-center">
              <small>Join Tournament</small> <Trophy size={20} />
            </button>
          </div>
          <hr className="my-4" />
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
                <p className="mb-0 text-secondary">
                  <small>Date</small>
                </p>
                <p className="mb-0 fw-semibold">November 8, 2024</p>
              </div>
              <div className="d-flex flex-column w-100 flex-wrap justify-content-between">
                <p className="mb-0 text-secondary">
                  <small>Entry Fee</small>
                </p>
                <p className="mb-0 fw-semibold">2000 Points</p>
              </div>
            </div>
            <div className="mt-3">
              <p className="fw-normal">
                <small>
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying on meaningful content.
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </small>
              </p>
            </div>
            <button className="text-warning bg-transparent fw-normal border-0 p-0 mb-1 d-flex gap-2 align-items-center">
              <small>Join Tournament</small> <Trophy size={20} />
            </button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="p-4 px-md-5 w-100 border-bottom">
        <h2 className="fw-semibold fs-1">Dashboard</h2>
        <p className="m-0 text-secondary">
          Review your <span className="fw-medium text-light">Bagdes</span>, find
          out about <span className="fw-medium text-light">Points</span> and see{" "}
          <span className="fw-medium text-light">Tournaments</span>.
        </p>
      </div>
      {showCompleteProfile && (
        <div className="d-flex flex-column gap-3 p-4 px-md-5 border-bottom">
          <p className="mb-0 text-secondary">
            Manage your informations, complete all fields to receive a Club
            Member Badge.
          </p>
          <button
            onClick={onProfileClick}
            className="btn btn-warning rounded-pill w-auto ms-0 px-4 py-2 mx-auto mb-1"
          >
            Complete your profile
          </button>
        </div>
      )}
      <Accordion items={accordionItems} />
    </>
  );
};

export default MyDashboard;
