import { FunctionComponent, useCallback, useEffect, useState } from "react";

interface VerifyPhoneNumberProps {
  phone: string;
  errorMessage: string;
  continue: (verificationCode: string) => void;
}

const VerifyPhoneNumber: FunctionComponent<VerifyPhoneNumberProps> = ({ phone, errorMessage, continue: continueFunction }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationFailed, setVerificationFailed] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setVerificationFailed(true);
    }
  }, [errorMessage]);

  return (
    <>
      <div className="border-bottom p-4 p-md-5 w-100">
        <h1 className="m-0 fs-1 fw-semibold text-center">
          Confirm your number
        </h1>
        {verificationFailed && (
          <div className="text-warning text-center px-4 px-md-5 py-2 py-md-4">
            <p className="mb-0">
              <small>{errorMessage}</small>
            </p>
          </div>
        )}
      </div>
      <div className="p-4 pb-0 p-md-5 col-12 col-md-6 mx-auto">
        <p className="mb-0 text-center">Enter the code we sent to {phone}</p>
        <div className="form-floating my-3">
          <input
            type="text"
            className="form-control rounded-3"
            id="floatingInput"
            placeholder="Enter code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <label htmlFor="floatingInput">Enter code</label>
        </div>
        <p className="mb-0 text-center text-light">
          <small>
            By selecting <strong>Agree and continue</strong>, I agree to the{" "}
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer" className="link-light text-decoration-none fw-bold"
            >
              {" "}
              Terms and Conditions
            </a>{" "}
            and the{" "}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="link-light text-decoration-none fw-bold">
              Privacy Policy.
            </a>
          </small>
        </p>
      </div>
      <div className="p-4 pt-md-0 w-100 text-center">
        <button
          type="submit"
          className="btn btn-warning rounded-pill w-auto mx-auto px-3 py-2"
          onClick={() => continueFunction(verificationCode)}
        >
          Agree and continue
        </button>
      </div>
    </>
  );
};

export default VerifyPhoneNumber;
