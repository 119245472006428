import React from "react";
import { Challenge } from "../../models/Challenge";
import PlayerSongCard from "../battles/components/PlayerSongCard";
import { CheckCircle, Trash, XCircle } from "@phosphor-icons/react";
import { formatDate } from "../../utility/DateFormatter";
import { Badge } from "../../models/Badge";

interface ChallengeCardProps {
  challenge: Challenge;
  type: "SENT" | "RECEIVED";
  allBadges: Badge[];
  onCancelChallengeClick: (battleId: number) => void;
  onAcceptChallengeClick: (battleId: number) => void;
  onDeleteChallengeClick: (battleId: number) => void;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({
  challenge,
  type,
  allBadges,
  onCancelChallengeClick,
  onAcceptChallengeClick,
  onDeleteChallengeClick,
}) => {
  if (!challenge) return null;

  const isExpired = challenge.status === "expired";

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div
        className={`rounded-4 p-3 h-100 ${isExpired ? "bg-dark" : "bg-dark-light"
          }`}
      >
        <p className="m-0 text-secondary">
          <small>
            {isExpired
              ? `Expired at ${formatDate(challenge.expirationDate)}`
              : `Expires at ${formatDate(new Date(challenge.expirationDate))}`}
          </small>
        </p>
        <div className="pt-3 row">
          <PlayerSongCard
            user={challenge.userData?.user}
            userStats={challenge.userData?.userStats}
            likes={-1}
            playerStatus={undefined}
            song={challenge.userData?.video?.song}
            videoUrl={challenge.userData?.video?.videoUrl}
            thumbnailUrl={challenge.userData?.video?.thumbnailUrl}
            tabType={"CHALLENGE"}
            allBadges={allBadges}
            playerOrder={1}
            onLikeClick={() => { }}
            isPlayerLikedByMe={false}
            battleStatus=""
            isBattleLikedByMe={false}
          />
          <PlayerSongCard
            user={challenge.opponentData?.user}
            userStats={challenge.opponentData?.userStats}
            likes={-1}
            playerStatus={undefined}
            song={challenge.opponentData?.video?.song}
            videoUrl={challenge.opponentData?.video?.videoUrl}
            thumbnailUrl={challenge.opponentData?.video?.thumbnailUrl}
            tabType={"CHALLENGE"}
            allBadges={allBadges}
            playerOrder={2}
            onLikeClick={() => { }}
            isPlayerLikedByMe={false}
            battleStatus=""
            isBattleLikedByMe={false}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-3">
          {isExpired ? (
            <div className="d-flex justify-content-end w-100">
              <button
                onClick={() => onDeleteChallengeClick(challenge.id)}
                className="bg-transparent border-0 text-yellow fs-6 p-0"
              >
                <small>
                  Delete <Trash size={24} />
                </small>
              </button>
            </div>
          ) : (
            <>
              {/* SENT challenge buttons */}
              {type === "SENT" && (
                <div className="d-flex justify-content-end w-100">
                  <button
                    onClick={() => onCancelChallengeClick(challenge.id)}
                    className="bg-transparent border-0 text-yellow fs-6 p-0"
                    disabled={isExpired}
                  >
                    <small>
                      Cancel challenge <XCircle size={24} />
                    </small>
                  </button>
                </div>
              )}
              {/* RECEIVED challenge buttons */}
              {type === "RECEIVED" && (
                <div className="d-flex justify-content-between w-100">
                  <button
                    onClick={() => onCancelChallengeClick(challenge.id)}
                    className="bg-transparent border-0 text-secondary fs-6 p-0"
                  >
                    <small>
                      Reject challenge <XCircle size={24} />
                    </small>
                  </button>
                  <button
                    onClick={() => onAcceptChallengeClick(challenge.id)}
                    className="bg-transparent border-0 text-yellow fs-6 p-0"
                    disabled={isExpired}
                  >
                    <small>
                      Accept challenge <CheckCircle size={24} />
                    </small>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChallengeCard;
