import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import Avatar from "react-avatar-edit";
import { User, updateUser } from "../../api/users";
import { Spinner } from "../components/Spinner";
import { Warning } from "@phosphor-icons/react";

type AvatarType = {
  preview: string | undefined;
  src: string;
};

const Profile: FunctionComponent = () => {
  const authState = useAuth().authState.user;
  const { updateUserInfo } = useAuth();
  const initialUser: User = {
    id: authState?.id || "0",
    firstName: authState?.firstName || "",
    lastName: authState?.lastName || "",
    email: authState?.email || "",
    phone: authState?.phone || "",
    picture: authState?.picture || "",
    nickname: authState?.nickname || "",
  };
  const [user, setUser] = useState<User>(initialUser);
  const [hasChanges, setHasChanges] = useState(false); // Track changes
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log("authState", authState);
    // Reset user state when authState changes
    setUser({
      id: authState?.id || "0",
      firstName: authState?.firstName || "",
      lastName: authState?.lastName || "",
      email: authState?.email || "",
      phone: authState?.phone || "",
      picture: authState?.picture || "",
      nickname: authState?.nickname || "",
    });
    setState({ preview: authState?.picture, src: "" });
  }, [authState]);

  const [state, setState] = useState<AvatarType>({
    preview: user?.picture,
    src: "",
  });

  const onClose = () => {
    //setState({ ...state, preview: undefined });
  };

  const onCrop = (preview: string) => {
    setState({ ...state, preview });
    setUser({ ...user, picture: preview });
  };

  const onBeforeFileLoad = (elem: any) => {
    // Implement any necessary checks before file load
  };

  const saveUser = async () => {
    setLoading(true); // Show loading indicator
    try {
      const response = await updateUser(user);
      updateUserInfo(
        response.firstName,
        response.lastName,
        response.picture,
        response.phone,
        response.nickname
      );
      setError(null); // Clear any previous error message on success
      setHasChanges(false); // Reset changes state after saving
    } catch (error: any) {
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const errorMessage = (responseData as { message: string }).message;
        console.error("Update failed:", errorMessage);
        setError(errorMessage); // Set the error message state
      } else {
        console.error("Update failed:", error);
        setError(
          "An unexpected error occurred while updating your information."
        ); // Generic error message
      }
    } finally {
      setTimeout(() => {
        setLoading(false); // Hide loading indicator
      }, 500);
    }
  };

  // Helper function to check for changes
  const checkForChanges = () => {
    const isChanged =
      user.firstName !== initialUser.firstName ||
      user.lastName !== initialUser.lastName ||
      user.phone !== initialUser.phone ||
      user.picture !== initialUser.picture ||
      user.nickname !== initialUser.nickname;
    setHasChanges(isChanged);
  };

  // Run the change checker whenever user state updates
  useEffect(() => {
    checkForChanges();
  }, [user, initialUser]);

  function phoneHasChanged(event: ChangeEvent<HTMLInputElement>): void {
    setUser({ ...user, phone: event.target.value });
  }

  function firstNameHasChanged(event: ChangeEvent<HTMLInputElement>): void {
    setUser({ ...user, firstName: event.target.value });
  }

  function lastNameHasChanged(event: ChangeEvent<HTMLInputElement>): void {
    setUser({ ...user, lastName: event.target.value });
  }

  function nicknameHasChanged(event: ChangeEvent<HTMLInputElement>): void {
    setUser({ ...user, nickname: event.target.value });
  }

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-10 col-lg-8">
        <div className="">
          <div className="d-flex gap-4 mb-3">
            <img
              crossOrigin="anonymous"
              src={state.preview || "/profile_placeholder.png"}
              alt="Preview"
              width="80"
              height="80"
              className="rounded-3"
            />
            <div className="overflow-hidden upload-avatar-holder">
              <Avatar
                exportAsSquare
                exportSize={160}
                cropRadius={50}
                width={160}
                height={160}
                onCrop={onCrop}
                onClose={onClose}
                onBeforeFileLoad={onBeforeFileLoad}
                src={state.src}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="user-firstname"
                  value={user?.firstName}
                  onChange={firstNameHasChanged}
                />
                <label htmlFor="user-firstname">Firstname</label>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="user-lastname"
                  value={user?.lastName}
                  onChange={lastNameHasChanged}
                />
                <label htmlFor="user-lastname">Lastname</label>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="user-email"
                  value={user?.email}
                  readOnly // Assuming email cannot be edited
                />
                <label htmlFor="user-email">Email address</label>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-floating mb-3">
                <input
                  type="tel"
                  className="form-control"
                  id="user-phone"
                  value={user?.phone}
                  onChange={phoneHasChanged}
                />
                <label htmlFor="user-phone">Phone number</label>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="user-battle-name"
                  value={user?.nickname}
                  onChange={nicknameHasChanged}
                />
                <label htmlFor="user-battle-name">Battle name</label>
              </div>
            </div>
            <div className="col-12 col-md-6 text-end d-flex justify-content-between align-items-center">
              {error && (
                <div>
                  <small className="text-warning d-flex align-items-center gap-2">
                    <Warning size={24} /> {error}
                  </small>
                </div>
              )}
              {loading && <Spinner />}
              <button
                type="button"
                className={`btn rounded-pill me-0 ms-auto px-3 py-2 ${
                  hasChanges ? "btn-warning" : "btn-outline-warning"
                }`}
                onClick={saveUser}
                disabled={!hasChanges || loading}
              >
                {hasChanges ? "Save changes" : "Changes saved"}
              </button>
            </div>
            {/* <div className="col-12 col-md-6 text-end">
              <button
                type="button"
                className="btn btn-warning rounded-pill px-3 py-2"
                onClick={saveUser}
                disabled={!hasChanges || loading}
              >
                Save changes
              </button>
            </div>
            {loading && <Spinner />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
