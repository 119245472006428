import { FunctionComponent, useMemo, type CSSProperties, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  /** Action props */
  const onHomeClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);


  const onPrivacyPolicyClick = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onTermsAndConditionsClick = useCallback(() => {
    navigate("/terms-and-conditions");
  }, [navigate]);

  const onCookiesClick = useCallback(() => {
    console.log("cookies");
    navigate("/cookies");
  }, [navigate]);

  return (
    <footer className="">
      <div className="p-4 p-md-5 border-top">
        <button
          onClick={onHomeClick}
          className="logo text-white d-inline-flex align-items-center border-0 bg-transparent p-0 mb-3"
        >
          <img src="/images/gbc-logos/gbc-icon.svg" alt="" className="d-block" />
          <p className="fs-3 fw-semibold m-0 ps-3">Guitar Battle Club</p>
        </button>
        <p className="text-yellow">Learn songs, challenge &amp; battle friends.</p>
      </div>
      <div className="d-flex justify-content-between flex-wrap">
        <div className="col-6 col-md-6 p-4 px-md-5">
          <h5 className="fs-6 fw-semibold mb-4">Legal</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2"><button onClick={onPrivacyPolicyClick} className="nav-link p-0 text-secondary"><small>Privacy policy</small></button></li>
            <li className="nav-item mb-2"><button onClick={onTermsAndConditionsClick} className="nav-link p-0 text-secondary"><small>Terms &amp; conditions</small></button></li>
            <li className="nav-item mb-2"><button onClick={onCookiesClick} className="nav-link p-0 text-secondary"><small>Cookies</small></button></li>
          </ul>
        </div>
        <div className="col-6 col-md-6 p-4 px-md-5">
          <h5 className="fs-6 fw-semibold mb-4">We are social</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2"><a href="https://www.facebook.com/GuitarBattleClub/" target="_blank" className="nav-link p-0 text-secondary"><small>Facebook</small></a></li>
            <li className="nav-item mb-2"><a href="https://www.instagram.com/guitarbattleclub/" target="_blank" className="nav-link p-0 text-secondary"><small>Instagram</small></a></li>
            <li className="nav-item mb-2"><a href="#" target="_blank" className="nav-link p-0 text-secondary"><small>Tiktok</small></a></li>
            <li className="nav-item mb-2"><a href="#" target="_blank" className="nav-link p-0 text-secondary"><small>Linkedin</small></a></li>
            <li className="nav-item mb-2"><a href="#" target="_blank" className="nav-link p-0 text-secondary"><small>Youtube</small></a></li>
          </ul>
        </div>
      </div>
      <div className="p-4 px-md-5 border-top">
        <p className="text-body-secondary m-0 pb-4"><small>&copy; Guitar Battle Club {new Date().getFullYear()}.</small></p>
      </div>
    </footer>
  );
};
