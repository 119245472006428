import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Notification } from "../../models/Notification";
import { Helmet } from "react-helmet-async";
import { ArrowCircleLeft } from "@phosphor-icons/react";

const NotificationDetailPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const notification = location.state?.notification as Notification;

  console.log(notification);
  if (!notification) {
    return <p>Notification not found!</p>; // If there's no notification, show a message
  }

  return (
    <>
      <Helmet>
        <title>{notification.title}</title>
      </Helmet>
      <div className="p-4 px-md-5 w-100">
        <div className="d-flex justify-content-between align-items-center">
          <button
            onClick={() => navigate(-1)}
            className="bg-transparent border-0 rounded-0 px-0 text-yellow"
          >
            <small className="d-flex gap-2 align-items-center">
              <ArrowCircleLeft size={24} />
              Back
            </small>
          </button>
          <span>
            <small className="text-secondary">
              {new Date(notification.createdAt).toLocaleString()}
            </small>
          </span>
        </div>
        <h3 className="card-title m-0">{notification.title}</h3>

        {notification.html ? (
          <div
            className="bg-dark-light rounded-4 overflow-hidden mt-4"
            dangerouslySetInnerHTML={{ __html: notification.html }}
          ></div>
        ) : (
          <p className="mt-4">{notification.message}</p>
        )}
      </div>
    </>
  );
};

export default NotificationDetailPage;
