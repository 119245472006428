import { FunctionComponent, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SingleBattleCard from "./components/SingleBattleCard";
import { getSingleBattle, addLikeToBattle } from "../../api/battles";
import { Battle } from "../../models/Battle";
import {
  generateSocialShareTitle,
  handleLikeBattle,
  checkIsBattleLikedByMe,
  checkIsPlayerLikedByMe,
} from "./utility/BattlesUtility";
import { useBadges } from "../../providers/BadgesProvider";
import BattleCard from "./components/BattleCard";
import { useAuth } from "../../providers/AuthProvider";

const SingleBattlePage: FunctionComponent = () => {
  const { badges } = useBadges();
  const { id } = useParams<{ id: string }>();
  const battleId = Number(id);
  const [battle, setBattle] = useState<Battle | null>(null);
  const [loading, setLoading] = useState(true);
  const userId = useAuth().authState.user?.id;

  const fetchSingleBattle = async (battleId: number) => {
    try {
      const result = await getSingleBattle(battleId);
      setBattle(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSingleBattle(battleId);
  }, [battleId]);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 275);
    });
  }, []);

  return (
    <>
      {battle && (
          <BattleCard
            key={battle.id}
            battle={battle}
            type= "ACTIVE"
            onBattleClick={() => {}}
            currentUserId={userId}
            allBadges={badges}
          />

      )}
    </>
  );
};

export default SingleBattlePage;
