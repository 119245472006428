import {
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import { useAuth } from "../../providers/AuthProvider";
import { submitFeedback } from "../../api/admin";
import { Point } from "../../models/PointCategory";
import { Dot } from "@phosphor-icons/react";

const AdminVideoFeedback: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useAuth();
  const isAdmin = authState.user?.isAdmin;
  const { userVideo } = location.state;
  const [points, setPoints] = useState<Point[]>(userVideo?.points || []);

  // console.log(userVideo);

  useEffect(() => {
    if (isAdmin === false) {
      navigate("/home");
    }
  }, [isAdmin, navigate]);

  function onBackClick(): void {
    navigate("/admin");
  }

  const sendFeedback = useCallback(() => {
    // Send feedback to the server
    submitFeedback(userVideo?.id, points).then(() => {
      navigate("/admin");
    });
  }, [userVideo?.id, 0, navigate]);

  return (
    <>
      <div className="p-4 px-md-5 w-100 border-bottom">
        <h2 className="fw-semibold fs-1">Video Feedback</h2>
      </div>

      <div className="p-4 px-md-5">
        <div className="d-flex flex-column align-items-center">
          <div className="col-12 col-md-6 col-lg-6 mb-4">
            <div className="d-flex flex-column h-100">
              <div className="rounded-4 overflow-hidden">
                <AWSVideoPlayerForm
                  videoUrl={userVideo?.videoUrl}
                  thumbnailUrl={userVideo?.thumbnailUrl}
                  isPrivate={true}
                />
              </div>
              <div className="d-flex gap-3 w-100 my-3">
                <div>
                  <img
                    width="40"
                    height="40"
                    className="rounded-3 img-fluid"
                    crossOrigin="anonymous"
                    alt=""
                    src={userVideo?.userPicture || "/profile_placeholder.png"}
                  />
                </div>
                <div className="d-flex flex-column ">
                  <h3 className="mb-0 fs-6 fw-semibold">
                    <small>
                      {userVideo?.userBattleName}
                    </small>
                  </h3>
                  <div className="d-flex align-items-center gap-1">
                    <p className="m-0 fw-semibold text-truncate">
                      <small>{userVideo?.song?.artistName || " "}</small>
                    </p>
                    <Dot size={12} />
                    <p className="m-0 text-secondary text-truncate">
                      <small>{userVideo?.song?.songTitle || ""}</small>
                    </p>
                  </div>
                </div>
              </div>
              {points.map((point: Point) => {
                return (
                  <div className="d-flex flex-column mb-4" key={point.id}>
                    <div className="d-flex align-items-center justify-content-between gap-4 mb-2">
                      <label
                        htmlFor="lesson-feedback"
                        className="form-label mb-0"
                      >
                        {point.name}
                      </label>
                      <div className="text-capitalize rounded-pill border border-1 px-3 py-2 d-inline-flex w-25 text-center">
                        {point.points}
                      </div>
                    </div>
                    <input
                      type="range"
                      className="form-range"
                      min={0}
                      max={point.max_points}
                      step={1}
                      id={`lesson-feedback-${point.id}`}
                      value={point.points}
                      onChange={(event) => {
                        console.log(event.target.value);
                        point.points = parseInt(event.target.value);
                        setPoints([...points]);
                      }}
                    />
                  </div>
                );
              })}
              <div className="mt-4">
                <button
                  onClick={sendFeedback}
                  className="btn btn-warning rounded-pill"
                >
                  Submit feedback
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminVideoFeedback;
