import React, { useState, useEffect } from "react";
import axios, { AxiosRequestConfig, CancelToken } from "axios";
import { useAuth } from "../../providers/AuthProvider";
import SuccessVideoUploadPopup from "./SuccessVideoUploadPopup";
import ErrorPopup from "../../views/components/ErrorPopup";
import api from "../../api/config/apiConfig";
import { Song } from "../../models/Song";
import { Helmet } from "react-helmet-async";
import { Disable } from "react-disable";
import { VideoCamera } from "@phosphor-icons/react";

// Define an interface for the component props
interface VideoProcessorProps {
  song?: Song; // Make the song prop optional
  callback?: () => void;
}

const VideoProcessor: React.FC<VideoProcessorProps> = ({ song, callback }) => {
  const { authState, login } = useAuth();
  const { tokens } = authState;
  const [file, setFile] = useState<File | null>(null);
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [overallProgress, setOverallProgress] = useState(0);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [fileSizeError, setFileSizeError] = useState<string | null>(null); // New state for file size error
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (uploading) {
        event.preventDefault();
        event.returnValue = "Are you sure?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploading]);

  const handleUploadSuccess = () => {
    if (song) {
      setArtistName(song.artistName || "");
      setSongTitle(song.songTitle || "");
    }
    setShowSuccessPopup(false);
    setFile(null);
    setVideoPreview(null);
    setFileSizeError(null);
    var button = document.getElementsByClassName("accordion-button");
    if (button.length > 0) {
      (button[0] as HTMLElement).click();
    }
    callback && callback();
  };

  const onCloseErrorPopup = () => {
    setShowErrorPopup(false);
  };

  // State for the artistName and songTitle inputs
  const [artistName, setArtistName] = useState<string>("");
  const [songTitle, setSongTitle] = useState<string>("");

  // Prefill artistName and songTitle if song prop is provided
  useEffect(() => {
    if (song) {
      setArtistName(song.artistName || "");
      setSongTitle(song.songTitle || "");
    }
  }, [song]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(false);
    setArtistName("");
    setSongTitle("");

    const selectedFile = event.target.files?.[0] || null;

    // Reset the file input to allow re-selection of the same file
    event.target.value = "";

    // Revoke previous video URL to prevent memory leak
    if (videoPreview) {
      URL.revokeObjectURL(videoPreview);
    }

    if (selectedFile) {
      if (selectedFile.size > 1 * 1024 * 1024 * 1024) {
        // Check if file is larger than 1GB
        setFileSizeError(
          "File size exceeds 1GB. Please select a smaller file."
        );
        setFile(null);
        setVideoPreview(null);
      } else {
        setFileSizeError(null);
        setFile(selectedFile);
        setVideoPreview(URL.createObjectURL(selectedFile));
      }
    }
  };

  const uploadFileChunk = async (
    chunk: Blob,
    chunkIndex: number,
    totalChunks: number,
    setChunkProgress: (progress: number) => void,
    uploadId: string,
    cancelToken: CancelToken
  ) => {
    const formData = new FormData();
    formData.append("video", chunk);
    formData.append("chunkIndex", chunkIndex.toString());
    formData.append("totalChunks", totalChunks.toString());
    // Conditionally append songId if song is provided
    if (song && song.id) {
      formData.append("songId", song.id);
    } else {
      formData.append("songId", uploadId);
    }
    formData.append("artist", artistName);
    formData.append("title", songTitle);
    formData.append("uploadId", uploadId);

    const accessToken = tokens?.access_token || "";

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      onUploadProgress: (progressEvent) => {
        const loaded = progressEvent.loaded;
        const total = progressEvent.total || 1;
        const progress = Math.round((loaded / total) * 100);
        setChunkProgress(progress);
      },
      cancelToken, // Pass cancelToken here
      timeout: 0, // No timeout
    };

    try {
      const response = await api.post(`/video/upload-chunk`, formData, config);
      if (response.status === 200 && response.data.accessToken) {
        login(response.data.user, { access_token: response.data.accessToken, refresh_token: "" });
      }
    } catch (error) {
      console.error("Error uploading chunk:", error);
      throw error;
    }
  };

  const processAndUploadVideo = async () => {
    if (artistName.trim() === "" || songTitle.trim() === "") {
      setFileSizeError("Please enter artist name and song title");
      return;
    }
    if (!file) return;

    // const uploadId = uuidv4();
    const uploadId = new Date().getTime().toString();

    setUploading(true);
    setShowSuccessPopup(false); // Reset success popup state

    const chunkSize = 10 * 1024 * 1024; // 10MB
    const totalChunks = Math.ceil(file.size / chunkSize);
    const chunkProgress: number[] = Array(totalChunks).fill(0);

    const setChunkProgress = (index: number, progress: number) => {
      chunkProgress[index] = progress;
      const overallProgress =
        chunkProgress.reduce((a, b) => a + b, 0) / totalChunks;
      setOverallProgress(overallProgress);
      console.log(
        `Chunk ${index} progress: ${progress}%, Overall progress: ${overallProgress}%`
      );
    };

    const concurrentUploads = 5; // Limit the number of concurrent uploads
    let currentUploads = 0;

    // Create an AbortController instance
    const controller = new AbortController();
    const { signal } = controller;

    const uploadChunks = async () => {
      const uploadPromises: Promise<void>[] = [];
      const cancelTokenSource = axios.CancelToken.source(); // Create a CancelToken source

      for (let i = 0; i < totalChunks; i++) {
        while (currentUploads >= concurrentUploads) {
          // Wait until there is an available slot for uploading
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
        currentUploads++;
        const promise = uploadFileChunk(
          file.slice(i * chunkSize, (i + 1) * chunkSize),
          i,
          totalChunks,
          (progress) => setChunkProgress(i, progress),
          uploadId,
          cancelTokenSource.token
        )
          .finally(() => {
            currentUploads--;
          })
          .catch((error) => {
            console.error(`Error uploading chunk ${i}:`, error);
            // Optionally, handle or notify about the error
            cancelTokenSource.cancel("Aborting all uploads due to an error.");

            throw new Error("Upload interrupted due to an error.");
          });
        uploadPromises.push(promise);
      }

      // Use Promise.allSettled to handle all promises
      const results = await Promise.allSettled(uploadPromises);

      // Check if any promise was rejected
      const anyFailed = results.some((result) => result.status === "rejected");
      if (anyFailed) {
        throw new Error("One or more uploads failed.");
      }

      console.log("All upload promises have resolved");
    };

    try {
      await uploadChunks();
      if (chunkProgress.every((progress) => progress === 100)) {
        setOverallProgress(100); // Ensure progress is set to 100% upon completion
        setShowSuccessPopup(true); // Show success popup only after all chunks are uploaded
      } else {
        console.log("Something went wrong, try again later");
      }
    } catch (error) {
      console.error("Error uploading video chunks:", error);
      setShowErrorPopup(true);
    } finally {
      setUploading(false);
      setOverallProgress(0); // Reset overall progress
    }
  };

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>
          {song?.songTitle
            ? `Upload video - ${song.songTitle} by ${song.artistName}`
            : "Upload video"}
        </title>
      </Helmet>

      {showErrorPopup && (
        <ErrorPopup
          onClose={onCloseErrorPopup}
          title="Error"
          message="Something went wrong with the upload. Try again later."
        />
      )}
      {showSuccessPopup && (
        <SuccessVideoUploadPopup onClose={handleUploadSuccess} />
      )}
      {fileSizeError && (
        <p className="text-warning mb-2">
          <small>{fileSizeError}</small>
        </p> // Display file size error
      )}
      <div className="custom-upload d-flex d-lg-inline-flex flex-column flex-wrap flex-md-nowrap border border-warning rounded-4">
        {videoPreview && (
          <div className="d-flex flex-column gap-4 border-top-0 border-bottom border-warning p-3">
            <video
              key={videoPreview}
              controls
              style={{
                width: "240px",
                height: "auto",
              }}
              className="rounded-4 border"
            >
              <source src={videoPreview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <form action="" className="">
              <Disable disabled={typeof song !== "undefined"}>
                <div className="d-flex flex-column w-100 gap-2 mb-4">
                  <div className="form-floating w-100">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="artistName"
                      name="artistName"
                      value={artistName}
                      placeholder="Artist name"
                      onChange={(e) => setArtistName(e.target.value)}
                      required
                    />
                    <label htmlFor="artistName" className="form-label">
                      Artist name
                    </label>
                  </div>
                  <div className="form-floating w-100">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="songTitle"
                      name="songTitle"
                      value={songTitle}
                      placeholder="Song title"
                      onChange={(e) => setSongTitle(e.target.value)}
                      required
                    />
                    <label htmlFor="songTitle" className="form-label">
                      Song title
                    </label>
                  </div>
                </div>
              </Disable>
              {/* Checkbox for terms and conditions */}
              <div className="form-check w-100 gap-3 d-flex">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={isCheckboxChecked}
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                />
                <label
                  className="form-check-label lh-sm"
                  htmlFor="flexCheckDefault"
                >
                  <small>
                    By uploading a video, you agree to our
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    .
                  </small>
                </label>
              </div>
            </form>
            {/* Upload button */}
            <button
              className="btn btn-warning rounded-pill w-auto px-4 py-2 mx-auto mb-2"
              onClick={processAndUploadVideo}
              disabled={!file || uploading || !isCheckboxChecked}
            >
              {uploading
                ? `Uploading... ${Math.round(overallProgress)}%`
                : "Upload Video"}
            </button>
          </div>
        )}
        <label
          htmlFor="upload-recorded-video"
          className="custom-video-upload p-3 w-100"
        >
          <div className="d-flex flex-column align-items-center gap-4 h-100">
            <div>
              <p className="mb-0 text-center">
                Drag and drop your video file here, or click to upload from your
                device.
              </p>
            </div>
            <p className="btn btn-warning rounded-pill w-auto px-4 py-2 mx-auto mb-0 d-flex align-items-center gap-2">
              <VideoCamera size={24} /> Browse
            </p>
            <p className="m-0 text-secondary">Supported size 1GB or less.</p>
          </div>
          <input
            id="upload-recorded-video"
            type="file"
            accept="video/*"
            onChange={handleFileChange}
          />
        </label>
      </div>
    </>
  );
};

export default VideoProcessor;
