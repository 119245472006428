import Cookies from "js-cookie";
import { useEffect } from "react";
import { awardDailyLoginXP } from "../api/xp";
import { User } from "../models/User";

const DailyLogin = () => {
    const storedUser = localStorage.getItem("user");
    const user: User | null = storedUser ? JSON.parse(storedUser) : null;
    // Define XP rewards based on the day of the streak


    // Function to calculate the XP reward based on the streak day
    const getXpReward = () => {
        return 1;
    };

    // Function to handle daily login streak and award XP
    const handleDailyLogin = async () => {
        if (!user) return;
        if (user.type !== "user") return;
        const LAST_LOGIN_COOKIE = `lastLogin_${user.id}`;

        const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
        const lastLogin = Cookies.get(LAST_LOGIN_COOKIE);           // Get the last login date from cookies

        // Check if the user has logged in today already
        if (lastLogin === today) {
            console.log("User already logged in today, no XP awarded.");
            return;
        }


        // Calculate the XP reward based on the new streak count
        const xpToAward = getXpReward();

        // Update cookies with the new streak information
        Cookies.set(LAST_LOGIN_COOKIE, today, { expires: 7, sameSite: 'strict' });
        // Send XP update to the backend
        try {
            // Call the API to award XP based on the streak
            await awardDailyLoginXP(xpToAward);
            console.log(`Awarded ${xpToAward} XP for login.`);
        } catch (error) {
            console.error("Failed to award XP:", error);
        }
    };

    useEffect(() => {
        handleDailyLogin();
    }, []);
};

export default DailyLogin;