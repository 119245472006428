import api from "./config/apiConfig";
import { logger as Logger } from "../services/Logger";
import { Notification } from "../models/Notification";

export const getNotificationsByUserId = async (
  accessToken: string
): Promise<Notification[]> => {
  try {
    const response = await api.get(`/notifications`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get user notifications failed");
    }
  } catch (error) {
    Logger.error("Get user notifications failed:", error);
    throw error;
  }
};

export const markNotificationAsRead = async (
  accessToken: string,
  notificationId: number
): Promise<Notification> => {
  try {
    const response = await api.post(
      `/notifications/read`,
      { notificationId: notificationId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Mark notification as read failed");
    }
  } catch (error) {
    Logger.error("Mark notification as read failed:", error);
    throw error;
  }
};

export const getUnreadNotificationsCount = async (): Promise<number> => {
  try {
    const response = await api.get(`/notifications/unread-count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    if (response.status === 200 && typeof response.data.count === "number") {
      return response.data.count;
    } else {
      throw new Error("Unexpected API response format.");
    }
  } catch (error) {
    console.error("Get unread notifications count failed:", error);
    return 0;
  }
};

export const markAllNotificationAsRead = async (
  accessToken: string
): Promise<Notification> => {
  try {
    const response = await api.post(`/notifications/read-all`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Mark all notification as read failed");
    }
  } catch (error) {
    Logger.error("Mark all notification as read failed:", error);
    throw error;
  }
};

export const markNotificationAsDeleted = async (
  accessToken: string,
  notificationId: number
): Promise<Notification> => {
  try {
    const response = await api.post(
      `/notifications/delete`,
      { notificationId: notificationId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Mark notification as deleted failed");
    }
  } catch (error) {
    Logger.error("Mark notification as deleted failed:", error);
    throw error;
  }
};
