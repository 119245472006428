import React from "react";
import { OpponentVideo } from "../../models/OpponentVideo";
import { PaperPlaneRight } from "@phosphor-icons/react";
import { Badge } from "../../models/Badge";
import PlayerSongCard from "../battles/components/PlayerSongCard";

type OpponentCardProps = {
  opponentVideo: OpponentVideo;
  allBadges: Badge[];
  onChallengeToBattleClick: (
    opponentVideoId: number,
    opponentName: string
  ) => void;
};

const OpponentCard: React.FC<OpponentCardProps> = ({
  opponentVideo,
  allBadges,
  onChallengeToBattleClick,
}) => {
  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
      <div className="rounded-4 bg-dark-light p-3">
        <PlayerSongCard
          user={opponentVideo.user}
          userStats={opponentVideo?.userStats}
          likes={-1}
          playerStatus={undefined}
          song={opponentVideo.song}
          videoUrl={opponentVideo.videoUrl}
          thumbnailUrl={opponentVideo.thumbnailUrl}
          tabType="OPPONENTS"
          allBadges={allBadges}
          playerOrder={-1}
          isPlayerLikedByMe={false}
          onLikeClick={() => {}}
          battleStatus=""
          isBattleLikedByMe={false}
        />

        <div className="d-flex justify-content-end mt-4">
          <button
            className="bg-transparent border-0 text-yellow fs-6 p-0"
            onClick={() =>
              onChallengeToBattleClick(
                opponentVideo.id,
                opponentVideo.user.battleName
              )
            }
          >
            <small className="d-flex align-items-center gap-2">
              Send challenge
              <PaperPlaneRight size={20} />
            </small>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OpponentCard;
