import { forwardRef, useEffect, useState } from "react";
import { formatDate } from "../../../utility/DateFormatter";
import { Battle } from "../../../models/Battle";
import PlayerSongCard, { PlayerStatus } from "./PlayerSongCard";
import SocialShare from "./SocialShare";
import { ShareNetwork } from "@phosphor-icons/react";
import {
  checkIsBattleLikedByMe,
  checkIsPlayerLikedByMe,
  generateSocialShareTitle,
  handleLikeBattle,
} from "../utility/BattlesUtility";
import OffcanvasButton from "../../components/OffcanvasButton";
import Offcanvas from "../../components/Offcanvas";
import { Badge } from "../../../models/Badge";
import { addLikeToBattle } from "../../../api/battles";
import ThankYouForVoting from "../components/ThankYouForVoting";

export type BattleCardProps = {
  battle: Battle;
  type: string;
  onBattleClick: (battle: Battle) => void;
  currentUserId?: string;
  allBadges: Badge[];
};

const BattleCard = forwardRef<HTMLDivElement, BattleCardProps>(
  function BattleCard(props, ref) {
    const url = `${window.location.origin}/battles/${props.battle.id}`;

    const [battle, setBattle] = useState<Battle>(props.battle);
    const battleId = battle.id;
    const isBattleLikedByMe = checkIsBattleLikedByMe(battleId);
    const [loading, setLoading] = useState(false);
    const [showOffcanvasThanksForVote, setShowOffcanvasThanksForVote] =
      useState(false);

    const getStartingPlayerStatus = (battle: Battle): PlayerStatus => {
      if (battle.startingOpponent.likes > battle.opponent.likes) {
        return PlayerStatus.WINNER;
      } else if (battle.startingOpponent.likes < battle.opponent.likes) {
        return PlayerStatus.LOSER;
      } else {
        return PlayerStatus.TIE;
      }
    };

    const getOpponentPlayerStatus = (battle: Battle): PlayerStatus => {
      if (battle.opponent.likes > battle.startingOpponent.likes) {
        return PlayerStatus.WINNER;
      } else if (battle.opponent.likes < battle.startingOpponent.likes) {
        return PlayerStatus.LOSER;
      } else {
        return PlayerStatus.TIE;
      }
    };

    const onLikeClick = async (playerOrder: number) => {

      setLoading(true);
      try {
        if (!isBattleLikedByMe && battle !== null) {
          const result = await addLikeToBattle(battle.id, playerOrder);
          setBattle(result);
          handleLikeBattle(battleId, playerOrder);
          setShowOffcanvasThanksForVote(true);
        }
        setLoading(false);
      } catch (error) {
        console.error("Sending like failed:", error);
        setLoading(false);
      }
    };

    return (
      <div ref={ref} className="col-12 col-lg-6 mb-4">
        <div className="bg-dark-light rounded-4 p-3 h-100">
          <div className="d-flex justify-content-between">
            <p className="m-0 text-secondary">
              <small>
                {props.type === "FINISHED"
                  ? `Ended on ${formatDate(new Date(battle.endedAt))}`
                  : `Ends ${formatDate(new Date(battle.endedAt))}`}
              </small>
            </p>
            {/* Social Share */}
            <OffcanvasButton
              targetId="socialShare"
              label={<small>Share battle</small>}
              icon={<ShareNetwork size={20} />}
              className="d-flex gap-2 align-items-center bg-transparent p-0 border-0 text-yellow"
            />
            <Offcanvas id="socialShare">
              <div className="social-share">
                <div className="border-bottom p-4 px-md-5">
                  <h3 className="fw-semibold fs-1">
                    Share this battle with your friends!
                  </h3>
                  <p className="m-0 text-secondary">
                    Share your support for this awesome guitar player with your
                    friends and invite more people to join the fun!
                  </p>
                  <SocialShare
                    url={url}
                    title={generateSocialShareTitle(battle)}
                  />
                </div>
              </div>
            </Offcanvas>
            {/* Thank you for voting offcanvas */}
            <Offcanvas
              id="thank-you-for-voting"
              show={showOffcanvasThanksForVote}
              onHide={() => {
                setShowOffcanvasThanksForVote(false);
              }}
            >
              <ThankYouForVoting
                battleTitle={generateSocialShareTitle(battle)}
                battlelink={url}
                setShowThankYouForVoting={setShowOffcanvasThanksForVote}
              />
            </Offcanvas>
          </div>
          {/* Players data */}
          <div className="pt-3 row">
            <PlayerSongCard
              user={battle.startingOpponent?.user}
              userStats={battle.startingOpponent?.userStats}
              likes={battle.startingOpponent?.likes || 0}
              playerStatus={getStartingPlayerStatus(battle)}
              song={battle.startingOpponent?.video?.song}
              videoUrl={battle.startingOpponent?.video?.videoUrl}
              thumbnailUrl={battle.startingOpponent?.video?.thumbnailUrl}
              tabType={props.type}
              allBadges={props.allBadges}
              playerOrder={1}
              battleStatus={battle.status}
              onLikeClick={onLikeClick}
              isBattleLikedByMe={isBattleLikedByMe}
              isPlayerLikedByMe={checkIsPlayerLikedByMe(battle.id, 1)}
              showVoting={true}
            />
            <PlayerSongCard
              user={battle.opponent?.user}
              userStats={battle.opponent?.userStats}
              likes={battle.opponent?.likes || 0}
              playerStatus={getOpponentPlayerStatus(battle)}
              song={battle.opponent?.video?.song}
              videoUrl={battle.opponent?.video?.videoUrl}
              thumbnailUrl={battle.opponent?.video?.thumbnailUrl}
              tabType={props.type}
              allBadges={props.allBadges}
              playerOrder={2}
              battleStatus={battle.status}
              onLikeClick={onLikeClick}
              isBattleLikedByMe={isBattleLikedByMe}
              isPlayerLikedByMe={checkIsPlayerLikedByMe(battle.id, 2)}
              showVoting={true}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default BattleCard;
