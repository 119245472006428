import React, { FC, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";

interface SuccessVideoUploadPopupProps {
  onClose: () => void;
}

const SuccessVideoUploadPopup: FC<SuccessVideoUploadPopupProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickMyVideos = useCallback(() => {
    onClose();
  }, [navigate]);

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header>
        <Modal.Title className="fs-2 fw-bold">Success!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0 fs-5">
          Your video was uploaded! <br />
          It will be available on <span className="fw-semibold">
            My videos
          </span>{" "}
          in a few minutes.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-warning rounded-pill px-3 py-2 mx-auto"
          variant="secondary"
          onClick={onClickMyVideos}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessVideoUploadPopup;
