import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { getUserVideos } from "../../api/videos";
import MyVideoCard from "./MyVideoCard";
import { UserVideo } from "../../models/UserVideo";
import { Song } from "../../models/Song";
import { getSongById } from "../../api/songs";
import DeleteVideoConfirmationPopup from "../record_video/DeleteVideoConfirmationPopup";
import { Video } from "../../models/Video";
import { Helmet } from "react-helmet-async";

import VideoProcessor from "../record_video/VideoProcessor";
import Accordion from "../components/UploadVideoAccordion";

const MyVideos: FunctionComponent = () => {
  const [videos, setVideos] = useState<UserVideo[]>([]);
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null); // State to track the currently playing video
  const navigate = useNavigate();
  const { authState } = useAuth();
  const { tokens } = authState;
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const [userType, setUserType] = useState<string>(authState.user?.type || "");

  const fetchUserVideos = async () => {
    try {
      const accessToken = tokens?.access_token || "";
      const userVideos: UserVideo[] = await getUserVideos(accessToken);
      setVideos(userVideos);
    } catch (error) {
      console.error("Failed to fetch user videos:", error);
    }
  };

  useEffect(() => {
    fetchUserVideos();
  }, [tokens?.access_token]);

  useEffect(() => {
    setUserType(authState.user?.type || "");
  }, [authState.user?.type]);

  const onTryAgainClick = async (songId?: string) => {
    if (songId) {
      try {
        const song: Song = await getSongById(songId);
        song.hasUserVideo = true;
        navigate("/upload-video", { state: { song } });
      } catch (error) {
        console.error("Failed to fetch song details:", error);
      }
    }
  };

  const onFindOpponentClick = async (userVideo: UserVideo) => {
    if (userVideo) {
      navigate("/find-opponent", {
        state: { preselectedVideo: userVideo },
      });
    }
  };

  const handlePlayVideo = (videoId: string) => {
    setPlayingVideoId(videoId);
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 275);
    });
  }, []);

  // Define the accordion items
  const accordionItems = [
    {
      id: 1,
      title: 'Upload new video',
      content: <VideoProcessor callback={fetchUserVideos} />,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>{"My videos"}</title>
      </Helmet>

      {showConfirmationPopup && selectedVideo && (
        <DeleteVideoConfirmationPopup
          onHide={() => setShowConfirmationPopup(false)}
          video={selectedVideo}
          token={tokens?.access_token || ""}
          onSucess={fetchUserVideos}
        />
      )}
      <div className="p-4 px-md-5 w-100 border-bottom text-lg-center">
        {(userType === "user") ? (
          <>
            <h2 className="fw-semibold fs-1">Your Videos</h2>
            <p className="m-0 text-secondary">
              Find all your uploaded videos in one place. <br className="d-none d-lg-block" />After receiving feedback,
              challenge other players to a guitar battle!
            </p>
          </>
        ) : (
          <>
            <h2 className="fw-semibold fs-1">Become a Player</h2>
            <p className="m-0 text-secondary">
              Upload your first video and join the community of guitarists <br className="d-none d-lg-block" />After receiving feedback,
              challenge other players to a guitar battle!
            </p>
          </>
        )}
      </div>
      <div className="text-lg-center">
        <Accordion items={accordionItems} />
      </div>

      <div className="p-4 px-md-5">
        <div className="row">
          {videos.map((video) => (
            <div
              className="col-12 col-md-6 mb-4"
              key={`my-videos-${video.id}`}
            >
              <div className="bg-dark-light rounded-4 p-3 h-100">
                <MyVideoCard
                  id={video.id}
                  video={video}
                  isPlaying={playingVideoId === video.id}
                  isModalView={false}
                  onPlay={() => handlePlayVideo(video.id)}
                  onTryAgainClick={onTryAgainClick}
                  onFindOpponentClick={onFindOpponentClick}
                  onDelete={(video) => {
                    setSelectedVideo({
                      id: Number.parseInt(video.id),
                      thumbnailUrl: `${video.thumbnailUrl}`,
                      videoUrl: `${video.videoUrl}`,
                      userId: 0,
                      songId: `0`,
                      reviewState: ``,
                      percentage: 0,
                      createdAt: new Date(video.createdAt),
                    });
                    console.log("selectedVideo", selectedVideo);
                    setShowConfirmationPopup(true);
                  }}
                  onVideoSelected={() => { }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyVideos;
