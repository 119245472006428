import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthProvider";
import { loginPhone, validatePhone } from "../../../api/login";
import SignInWithPhoneForm from "../../sign_in/SignInWithPhoneForm";
import VerifyPhoneNumber from "../../sign_in/VerifyPhoneNumber";
import SocialShare from "./SocialShare";

interface ThankYouForVotingProps {
  battlelink: string;
  battleTitle: string;
  setShowThankYouForVoting: (value: boolean) => void;
}

const ThankYouForVoting: FC<ThankYouForVotingProps> = (props) => {
    const isAuthenticated = useAuth().authState.isAuthenticated;
    const { login } = useAuth();
    const navigate = useNavigate();
    const [userId, setUserId] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [registerInProgress, setRegisterInProgress] = useState<boolean>(false);
    const [registerFailed, setRegisterFailed] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const registerFollowerUser = async (data: {
        phone: string;
    }) => {
        try {
            const response = await loginPhone(data.phone, "follower");
            setRegisterInProgress(true);
            console.log(response);
            setUserId(response.userId);
            setPhone(response.phone);
        } catch (error) {
            setRegisterInProgress(false);
            setRegisterFailed(true);
        }
    }

    const onClickVerifyPhone = async (verificationCode: string) => {
        try {
            const response = await validatePhone(verificationCode, userId);
            console.log(response);
            if (response.accessToken && response.user) {
              login(response.user, {
                access_token: response.accessToken,
                refresh_token: "",
              });
              setRegisterInProgress(false);
              // const offcanvasElement = document.getElementById("thank-you-for-voting") as HTMLElement;
              // offcanvasElement.classList.remove('show')
              props.setShowThankYouForVoting(false);
            } else {
                setError(`Could not verify phone number. Please try again. ${response.message}`);
            }
        } catch (error: any) {
            setError(`Could not verify phone number. Please try again. ${error.response.data.message}`);
        }
    }

    return (
        <>
            <div className="p-4 px-md-5 border-bottom">
                {isAuthenticated && (
                    <>
                        <div className="">
                            <h3 className="fw-semibold fs-1">
                                Thanks for voting!
                            </h3>
                            <p className="m-0 text-secondary">Share your support for this awesome guitar player with your friends and invite more people to join the fun!</p>
                        </div>
                        <SocialShare url={props.battlelink} title={props.battleTitle} />
                    </>
                )}
                {!isAuthenticated && !registerInProgress && (
                    <>
                        <div className="mb-4">
                            <h3 className="fw-semibold fs-1">
                                Thanks for voting!
                            </h3>
                            <p className="m-0 text-secondary">Stay connected with Guitar Battle Club on Whatasapp to get updates on this battle and discover new challenges.</p>
                        </div>
                        <SignInWithPhoneForm
                            continue={registerFollowerUser}
                            clearErrorMessage={() => { }}
                        />
                    </>
                )}
                {registerInProgress && (
                    <VerifyPhoneNumber phone={phone} errorMessage={error} continue={onClickVerifyPhone} />
                )}
            </div>

        </>
    );
};

export default ThankYouForVoting;


