import React, { FunctionComponent, useEffect } from "react";
import { UserVideoForFeedback } from "../../models/UserVideoForFeedback";
import { formatDate } from "../../utility/DateFormatter";
import { Dot, CalendarDots, CloudArrowUp } from "@phosphor-icons/react";
import axios from "axios";

interface AdminUserVideoCardProps {
  video: UserVideoForFeedback; // Accept the whole UserVideoForFeedback object
  action: () => void; // Define the type for the action prop
}

const AdminUserVideoCard: FunctionComponent<AdminUserVideoCardProps> = ({
  video,
  action,
}) => {
  const localCreatedDate = formatDate(new Date(video.createdAt));
  const [thumbnailError, setThumbnailError] = React.useState(false);

  useEffect(() => {
    console.log("Thumbnail URL: ", video?.thumbnailUrl);
    if (video?.thumbnailUrl) {
      axios
        .get(video.thumbnailUrl)
        .then(() => {
          console.log("Thumbnail loaded successfully");
          setThumbnailError(false);
        })
        .catch(() => {
          console.log("Thumbnail failed to load");
          setThumbnailError(true);
        });
    } else {
      setThumbnailError(true);
    }
  }, [video?.thumbnailUrl]);

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="d-flex flex-column h-100">
        <div className="d-flex position-relative  rounded-4 border overflow-hidden h-100">
          {thumbnailError ? (
            <div className="d-flex flex-column w-100 h-100 bg-black p-5 text-white align-items-center justify-content-center text-center">
              <p className="fw-semibold">
                Your video is being processed and will be ready in just a few
                minutes.{" "}
              </p>
              <p className="text-secondary">
                You'll be able to watch it soon, hang tight!
              </p>
            </div>
          ) : (
            <img
              className="img-fluid"
              crossOrigin="anonymous"
              src={video?.thumbnailUrl}
            />
          )}

          <div className="position-absolute bottom-0 start-0 w-100 px-3 pb-3 pt-5 bg-gradient-bottom rounded-bottom-4 d-flex">
            <div className="d-flex gap-4 align-items-center">
              <div>
                <small className="d-flex gap-1 fw-light">
                  <CalendarDots size={20} />
                  {localCreatedDate.toLocaleString()}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center my-4 my-mx-5 gap-3">
          <div className="d-flex gap-3 w-100">
            <div>
              <img
                width="40"
                height="40"
                className="rounded-3 img-fluid"
                crossOrigin="anonymous"
                alt=""
                src={video.userPicture || "/profile_placeholder.png"}
              />
            </div>
            <div className="d-flex flex-column ">
              <h3 className="mb-0 fs-6 fw-semibold">
                <small>{video.userBattleName}</small>
              </h3>
              <div className="d-flex align-items-center gap-1">
                <p className="m-0 fw-semibold text-truncate">
                  <small>{video?.song?.artistName || ""}</small>
                </p>
                <Dot size={12} />
                <p className="m-0 text-secondary text-truncate">
                  <small>{video?.song?.songTitle || ""}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          className="btn btn-warning rounded-pill me-auto"
          onClick={action}
          disabled={thumbnailError}
        >
          Analyze video
        </button>
      </div>
    </div>
  );
};

export default AdminUserVideoCard;
