import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
// import "./css/gbc-style.css"; // Import global styles
// import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./css/redesign.css";
import store from "./data/store";

const container = document.getElementById("root");
const root = createRoot(container!);

// Add this to your main entry file (e.g., index.tsx)
window.addEventListener("unhandledrejection", (event) => {
  console.error(
    "Unhandled rejection (promise: ",
    event.promise,
    ", reason: ",
    event.reason,
    ")."
  );
});

window.addEventListener("error", (event) => {
  if (event.error) {
    console.error("Error occurred: ", event.error.message);
    console.error("Stack trace: ", event.error.stack);
  } else {
    console.error("Error details not available.");
    console.error("Error event: ", event);
  }

  // Optional: Prevent default behavior
  event.preventDefault();
});

// Conditional rendering of React.StrictMode
const AppRoot =
  process.env.NODE_ENV === "development" ? (
    <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </React.StrictMode>
  ) : (
    <HelmetProvider>
      <App />
    </HelmetProvider>
  );

root.render(
  <Provider store={store}>
    <BrowserRouter>{AppRoot}</BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
