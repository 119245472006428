import React, { useState, FormEvent, useEffect } from "react";
import {
  parsePhoneNumberFromString,
  getCountries,
  getCountryCallingCode,
} from "libphonenumber-js";
import { isNullOrEmpty } from "../../utility/AppUtility";
import Flag from "react-world-flags";
import Select from "react-select";

interface SignInWithPhoneProps {
  continue: (data: { phone: string }) => void;
  clearErrorMessage: () => void;
}

const COUNTRY_CODES = getCountries().map((code) => ({
  code, // e.g., "RO"
  dialCode: `+${getCountryCallingCode(code)}`, // e.g., "+40"
  name: new Intl.DisplayNames(["en"], { type: "region" }).of(code) || code,
}));

type CountryCode = (typeof COUNTRY_CODES)[number]["code"];

const SignInWithPhoneForm: React.FC<SignInWithPhoneProps> = (props) => {
  const [countryCode, setCountryCode] = useState<CountryCode | "">("");
  const [dialCode, setDialCode] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [isTouched, setIsTouched] = useState(false);

  const CACHE_KEY = "userCountry";
  const CACHE_EXPIRATION_KEY = "userCountryExpiration";
  const CACHE_TTL = 365 * 24 * 60 * 60 * 1000; // 1 year in milliseconds

  useEffect(() => {
    checkCacheAndFetchUserCountry();
  }, []);

  const checkCacheAndFetchUserCountry = () => {
    const cachedCountry = localStorage.getItem(CACHE_KEY);
    const cachedExpiration = localStorage.getItem(CACHE_EXPIRATION_KEY);

    if (
      cachedCountry &&
      cachedExpiration &&
      Date.now() < parseInt(cachedExpiration, 10)
    ) {
      const selectedCountry = COUNTRY_CODES.find(
        (c) => c.code === cachedCountry
      );
      if (selectedCountry) {
        setCountryCode(selectedCountry.code);
        setDialCode(selectedCountry.dialCode);
        return;
      }
    }

    fetchUserCountry();
  };

  const fetchUserCountry = async () => {
    try {
      const response = await fetch(
        "https://ipinfo.io/json?token=1453bd66a93b0a"
      );
      const data = await response.json();
      const userCountry = data.country?.toUpperCase();

      if (userCountry) {
        localStorage.setItem(CACHE_KEY, userCountry);
        localStorage.setItem(
          CACHE_EXPIRATION_KEY,
          (Date.now() + CACHE_TTL).toString()
        );

        const selectedCountry = COUNTRY_CODES.find(
          (c) => c.code === userCountry
        );
        if (selectedCountry) {
          setCountryCode(selectedCountry.code);
          setDialCode(selectedCountry.dialCode);
        }
      }
    } catch (error) {
      console.error("Error fetching user country:", error);
    }
  };

  const handleCountryCodeChange = (selectedOption: any) => {
    if (selectedOption) {
      setCountryCode(selectedOption.value);
      setDialCode(selectedOption.dialCode);
      setError(""); // Reset error on country code change
    }
  };

  const handlePhoneChange = (value: string) => {
    setPhone(value);
    if (isTouched) {
      validatePhone(value);
    }
  };

  const isValidPhoneNumber = (value: string): boolean => {
    if (countryCode === "" || isNullOrEmpty(value)) {
      setError("Phone number is required");
      return false;
    }

    // Parse the phone number using the provided country code
    const phoneNumber = parsePhoneNumberFromString(value, countryCode);

    // Check if the phone number is valid
    const isValid = phoneNumber?.isValid();

    // If valid, set the national number (local format)
    if (phoneNumber?.number && isValid) {
      console.log("Valid phone number:", phoneNumber);
      setPhone(phoneNumber?.nationalNumber); // Save the local (national) phone number for display
    }

    // Return validity of phone number
    return isValid ?? false;
  };

  const validatePhone = (value: string) => {
    if (!isValidPhoneNumber(value)) {
      setError("Invalid phone number for " + countryCode);
      return false;
    }
    setError(""); // Clear error if valid
    return true;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isNullOrEmpty(phone) || !isValidPhoneNumber(phone)) {
      return;
    }
    console.log("Phone number:", `${dialCode}${phone}`);
    props.continue({ phone: `${dialCode}${phone}` });
  };

  const handleBlur = () => {
    setIsTouched(true);
    if (!isNullOrEmpty(phone)) {
      validatePhone(phone);
    }
  };

  const inputClassName = error ? "form-control is-invalid" : "form-control";

  // Adjusted react-select options
  const countryOptions = COUNTRY_CODES.map((country) => ({
    value: country.code,
    label: `${country.name} (${country.dialCode})`, // Just the label as a string
    dialCode: country.dialCode,
  }));

  const customStyles = {
    control: (base: any) => ({
      ...base,
      backgroundColor: "#000", // Change the background color here
      borderColor: "#ccc", // Border color
      boxShadow: "none", // Remove the shadow (optional)
      "&:hover": {
        borderColor: "#bbb", // Change hover color
      },

      zIndex: 10, // Ensure that the control itself is on top
    }),
    option: (base: any) => ({
      ...base,
      backgroundColor: "#000", // Option background color
      color: "#333", // Text color
      "&:hover": {
        backgroundColor: "#f4f4f4", // Option hover color
      },
      xIndex: 10, // Ensure that the options are on top
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: "#000", // Menu background color,
      margin: 0,
      padding: 0,
      zIndex: 10, // Ensure that the menu is on top
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "#333", // Single value text color
    }),
    placeholder: (base: any) => ({
      ...base,
      color: "#999", // Placeholder text color
      padding: 0,
    }),
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="d-flex flex-column">
          <div className="form-floating">
            <Select
              options={countryOptions}
              value={
                countryOptions.find((option) => option.value === countryCode) ||
                null
              }
              onChange={handleCountryCodeChange}
              // className="form-control rounded-top-3 rounded-bottom-0"
              styles={customStyles} // Apply custom styles
              placeholder="Select country code"
              formatOptionLabel={(data: any) => (
                <div className="d-flex align-items-center">
                  <Flag
                    code={data.value.toUpperCase()}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "10px",
                    }}
                    alt={`${data.label} flag`}
                  />

                  <p className="m-0 text-secondary text-truncate">
                    <small>{data.label}</small>
                  </p>
                </div>
              )}
            />
            {/* <label htmlFor="country_code">Country code</label> */}
          </div>
          <div className="has-phone-number form-floating mt-n1">
            <input
              type="tel"
              className={`${inputClassName} rounded-top-0 rounded-bottom-3`}
              id="phone_number required"
              placeholder="WhatsApp number"
              value={phone}
              onChange={(e) => handlePhoneChange(e.target.value)}
              onBlur={handleBlur}
            />
            <label htmlFor="phone_number">WhatsApp number</label>
            <span className="country-code">({dialCode})</span>
          </div>
        </div>
      </div>
      <div className="row align-items-center justify-content-center">
        {/* Forgot password */}
        {/* <div className="col-12">
          <button
            type="button"
            className="text-yellow w-100 text-decoration-none fw-normal text-center bg-transparent border-0 mt-2 mx-0 px-0"
            onClick={props.onForgotPasswordClick}
          >
            <small>Forgot your password?</small>
          </button>
        </div> */}
        {/* Log in now */}
        <div className="col-12 col-md-12 d-flex flex-column align-items-center">
          <p className="mb-0 py-2 text-secondary"><small>We'll text you to confirm your number. </small></p>
          <button
            type="submit"
            className="btn btn-warning rounded-pill w-auto mx-auto my-4 mt-2 px-3 py-2"
          >
            Continue
          </button>
        </div>
      </div>
    </form>
  );
};

export default SignInWithPhoneForm;
