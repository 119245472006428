import { FunctionComponent, useEffect, useState } from "react";
import { User } from "../../../models/User";
import { Trophy, Heart, Dot, LockSimple, Eye, PlayCircle } from "@phosphor-icons/react";
import { UserStats } from "../../../models/UserStats";
import UserBadgesSmallDisplay from "./UserBadgesSmallDisplay";
import BattlesSmallDisplay from "./BattlesSmallDisplay";
import OffcanvasButton from "../../components/OffcanvasButton";
import Offcanvas from "../../components/Offcanvas";
import PublicProfile from "../../components/PublicProfile";
import AWSVideoPlayerForm from "../../components/AWSVideoPlayerForm";
import { Badge } from "../../../models/Badge";

export enum PlayerStatus {
  WINNER = "WINNER",
  TIE = "TIE",
  LOSER = "LOSER",
}

export type PlayerSongCardProps = {
  user: User;
  userStats: UserStats;
  likes: number;
  playerStatus?: PlayerStatus;
  song: {
    artistName: string;
    songTitle: string;
  };
  videoUrl?: string;
  thumbnailUrl?: string;
  tabType: string;
  allBadges: Badge[];
  showVoting?: boolean;
  playerOrder: number;
  battleStatus: string;
  onLikeClick: (playerOrder: number) => void;
  isBattleLikedByMe: boolean;
  isPlayerLikedByMe: boolean;
};

const PlayerSongCard: FunctionComponent<PlayerSongCardProps> = ({
  user,
  userStats,
  likes,
  playerStatus,
  song,
  tabType,
  videoUrl,
  thumbnailUrl,
  allBadges,
  onLikeClick,
  battleStatus,
  isBattleLikedByMe,
  isPlayerLikedByMe,
  playerOrder,
  showVoting = false,
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const offcanvasIdPublicProfile = `publicProfile-${user.id}`;
  const offcanvasIdVideo = `video-${videoUrl}`;

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    console.log("isVideoPlaying changed:", isVideoPlaying);
  }, [isVideoPlaying]);

  console.log(userStats);
  return (
    <div className="col-6">
      {videoUrl && (
        <div className="d-flex rounded-3 overflow-hidden position-relative mb-3">
          <AWSVideoPlayerForm
            videoUrl={videoUrl}
            thumbnailUrl={thumbnailUrl}
            isPrivate={true}
            autoplay={false}
            onPlay={() => setIsVideoPlaying(true)}
            onPause={() => setIsVideoPlaying(false)}
          />

          <div
            className={`song-info pe-none position-absolute bottom-0 start-0 end-0 p-3 bg-blur w-100 ${isVideoPlaying
              ? "d-none"
              : "d-flex flex-column justify-content-end"
              }`}
          >
            <div className="d-flex flex-column mb-2">
              <p className="m-0 text-white fw-semibold w-100 d-block">
                <small className="text-truncate d-block">
                  {song?.artistName || ""}
                </small>
              </p>
              <p className="m-0 text-secondary text-truncate w-100 d-block">
                <small className="text-truncate d-block">
                  {song?.songTitle || ""}
                </small>
              </p>
            </div>
            <div>
              {likes >= 0 && (
                <span>
                  {tabType === "FINISHED" &&
                    (playerStatus == "WINNER" || playerStatus == "TIE") ? (
                    <small className="d-flex align-items-center gap-2 text-yellow mh-26">
                      {playerStatus == "TIE" ? "Tie" : "Winner"}{" "}
                      <Heart size={14} weight="fill" /> {likes}
                    </small>
                  ) : (
                    <small className="d-flex align-items-center gap-2 text-white mh-26">
                      <Heart size={14} weight="fill" /> {likes}
                    </small>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      <OffcanvasButton
        targetId={offcanvasIdPublicProfile}
        className="bg-transparent p-0 border-0 w-100"
        label={
          <>
            <div className="d-flex align-items-center gap-3 mb-2">
              <img
                width="40"
                height="40"
                className="rounded-3 img-fluid "
                crossOrigin="anonymous"
                alt={user?.battleName || ""}
                src={user?.pictureUrl || "/profile_placeholder.png"}
              />
              <div className="d-flex flex-column overflow-hidden">
                <h3 className="mb-1 fs-6 fw-medium text-start d-block text-white">
                  <small className="text-truncate d-block">
                    {user?.battleName}
                  </small>
                </h3>

                <div className="user-info d-flex align-items-center gap-2">
                  {/* Show badges if user has any  */}
                  <UserBadgesSmallDisplay
                    userBadges={userStats?.badges}
                    allBadges={allBadges}
                  />
                  <div className="ms-2 d-none d-sm-block">
                    <BattlesSmallDisplay
                      wins={userStats?.winningBattles || 0}
                      total={userStats?.totalBattles || 0}
                    />
                    {/* 
                  <span>
                    <small className="d-flex align-items-center text-yellow gap-1">
                      <Trophy size={18} /> 29 / 756
                    </small>
                  </span> 
                */}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-block d-sm-none">
              <BattlesSmallDisplay
                wins={userStats?.winningBattles || 0}
                total={userStats?.totalBattles || 0}
              />
              {/* 
                  <span>
                    <small className="d-flex align-items-center text-yellow gap-1">
                      <Trophy size={18} /> 29 / 756
                    </small>
                  </span> 
                */}
            </div>
          </>
        }
      />
      <Offcanvas id={offcanvasIdPublicProfile}>
        <PublicProfile
          user={user}
          userStats={userStats}
          allBadges={allBadges}
        />
      </Offcanvas>
      {showVoting && (
        <div className="text-center vote-video-btn mt-3 d-flex align-items-center justify-content-center w-100">
          {isBattleLikedByMe || battleStatus === "finished" ? (
            isPlayerLikedByMe ? (
              <div className="w-100 text-center d-flex justify-content-center align-items-center gap-2 border border-secondary bg-secondary text-dark rounded-3 px-3 py-1 fs-14">
                <span className="d-flex align-items-center gap-1">
                  <Heart size={20} weight="fill" />
                </span>
                <span className="mh-26">Voted</span>
              </div>
            ) : (
              <div className="w-100 text-center d-flex justify-content-center align-items-center gap-2 border border-secondary text-secondary rounded-3 px-3 py-1 fs-14">
                <span className="d-flex align-items-center gap-1">
                  <Heart size={20} weight="fill" />
                </span>
                <span className="mh-26">Vote</span>
              </div>
            )
          ) : (
            <>
              <div className="text-center vote-video-btn d-flex align-items-center justify-content-center w-100">
                <input
                  type="checkbox"
                  disabled={isBattleLikedByMe || battleStatus === "finished"}
                  onChange={() => onLikeClick(playerOrder)}
                  id={`like-checkbox-${playerOrder}`}
                  checked={isPlayerLikedByMe}
                />
                <label
                  htmlFor={`like-checkbox-${playerOrder}`}
                  role="button"
                  className="w-100 text-center d-flex justify-content-center align-items-center rounded-3"
                >
                  <span>
                    <div className="position-relative d-flex justify-content-center align-items-center">
                      <Heart
                        size={20}
                        weight="fill"
                        className="heart-pulsing-1 color-black position-absolute"
                      />
                      <Heart
                        size={20}
                        weight="fill"
                        className="heart-pulsing-2 color-black"
                      />
                    </div>
                  </span>
                  <span className="mh-26">Vote</span>
                </label>
              </div>
              {/* <div className="text-center vote-video-btn mt-3 d-flex align-items-center justify-content-center w-100">
                <input
                  type="checkbox"
                // disabled={isBattleLikedByMe || battleStatus === "finished"}
                // onChange={() => onLikeClick(playerOrder)}
                // id={`like-checkbox-${playerOrder}`}
                // checked={isPlayerLikedByMe}
                />
                {/* <label htmlFor={`like-checkbox-${playerOrder}`}> */}
              {/* <label className="w-100 text-center d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <Heart
                      size={20}
                      weight="fill"
                      className="heart-pulsing-1 color-black position-absolute"
                    />
                    <Heart
                      size={20}
                      weight="fill"
                      className="heart-pulsing-2 color-black"
                    />
                  </div>
                  <p className="m-0 py-1">Vote</p>
                </label>
              </div>  */}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PlayerSongCard;
