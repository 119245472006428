import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useAuth } from "../../providers/AuthProvider";
import UserMenu from "./UserMenu";
import QuickMenu from "./QuickMenu";
import { getUnreadNotificationsCount } from "../../api/notifications";

export const MainLayout = () => {
  const { authState } = useAuth();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState<number>(0);
  const location = useLocation();

  useEffect(() => {
    if (authState.isAuthenticated) {
      fetchUnreadNotificationsCount();
    }
  }, [authState.user, location]);

  const fetchUnreadNotificationsCount = async () => {
    try {
      const count = await getUnreadNotificationsCount();
      setUnreadNotificationsCount(count);
    } catch (error) {
      console.error("Failed to fetch notifications count:", error);
    }
  };

  return (
    <div>
      <Header />
      {authState.isAuthenticated && (
        <UserMenu
          unreadNotificationsCount={unreadNotificationsCount}
          refreshUnreadNotificationsCount={fetchUnreadNotificationsCount}
        />
      )}
      <main className="d-flex flex-wrap w-100">
        <Outlet context={{ setUnreadNotificationsCount }} />
      </main>
      <QuickMenu />
      <Footer />
    </div>
  );
};
