import {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BattleTabs from "./components/BattleTabs";
import BattleCard from "./components/BattleCard";
import { getAllBattles } from "../../api/battles";
import { Battle } from "../../models/Battle";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../../providers/AuthProvider";
import { useBadges } from "../../providers/BadgesProvider";

const BattlesPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { badges } = useBadges();

  const { authState } = useAuth();
  const userId = authState.user?.id;

  const [battles, setBattles] = useState<Battle[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [remainingPages, setRemainingPages] = useState<number>(0);

  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastFetchedPage = useRef(1); // Track last fetched page to prevent double-fetching

  // Determine the selected tab based on the URL path
  const selectedTab = location.pathname.includes("active")
    ? "ACTIVE"
    : location.pathname.includes("finished")
      ? "FINISHED"
      : location.pathname.includes("find-opponents")
        ? "FIND_OPPONENTS"
        : "ACTIVE";

  const fetchBattles = async (pageNumber: number) => {
    try {
      if (pageNumber > 1) {
        setLoadingMore(true);
      } else {
        setLoading(true);
      }

      const {
        battles: newBattles,
        hasMore,
        remainingPages,
      } = await getAllBattles(selectedTab.toLowerCase(), pageNumber, 9);

      setBattles((prev) =>
        pageNumber === 1 ? newBattles : [...prev, ...newBattles]
      );
      setHasMore(hasMore);
      setRemainingPages(remainingPages);
    } catch (error) {
      setError(
        error instanceof Error ? error : new Error("Failed to fetch battles")
      );
    } finally {
      setLoading(false);
      setLoadingMore(false); // Reset loadingMore
    }
  };

  useEffect(() => {
    setPage(1);
    setBattles([]); // Clear battles on tab change
    lastFetchedPage.current = 1; // Reset last fetched page
    fetchBattles(1);
  }, [selectedTab]);

  const lastBattleRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || loadingMore || !hasMore) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && lastFetchedPage.current < page + 1) {
            lastFetchedPage.current = page + 1; // Update last fetched page
            fetchBattles(page + 1);
            setPage((prevPage) => prevPage + 1);
          }
        },
        { threshold: 1 }
      );

      if (node) observerRef.current.observe(node);
    },
    [loading, loadingMore, hasMore, page]
  );

  // Handle tab change and update URL path
  const handleTabChange = (tab: string) => {
    if (tab === "ACTIVE") {
      navigate("/battles/active");
    } else if (tab === "FINISHED") {
      navigate("/battles/finished");
    } else if (tab === "FIND_OPPONENTS") {
      navigate("/battles/find-opponents");
    }
  };

  const onBattleClick = (battle: Battle) => {
    navigate(`/battles/${battle.id}`);
  };

  return (
    <>
      <Helmet>
        <title>{"Battles"}</title>
      </Helmet>
      <div className="p-4 px-md-5 w-100 border-bottom">
        <div className="text-lg-center">
          <h2 className="fw-semibold fs-1">Battle Arena</h2>
          <p className="mb-4 text-secondary">
            Challenge others to guitar battles. Invite your friends{" "}
            <br className="d-none d-lg-block" />
            to vote for you to win the battle and earn rewards.
          </p>
          <BattleTabs onTabChange={handleTabChange} selectedTab={selectedTab} />
        </div>
      </div>
      <div className="p-4 px-md-5 w-100">
        {loading && (
          <div className="p-4 px-md-5 text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {!loading && battles.length === 0 && (
          <div className="p-4 px-md-5 text-center">
            <p>No {selectedTab.toLowerCase()} battles yet.</p>
          </div>
        )}
        <div className="row">
          {battles.map((battle, index) => (
            <BattleCard
              key={battle.id}
              battle={battle}
              ref={index === battles.length - 1 ? lastBattleRef : null}
              type={selectedTab}
              onBattleClick={onBattleClick}
              currentUserId={userId}
              allBadges={badges}
            />
          ))}
        </div>
        {/* Show Loading Spinner for Infinite Scroll */}
        {loadingMore && (
          <div className="text-center my-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading more...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BattlesPage;
