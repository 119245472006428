import { X } from "@phosphor-icons/react";
import React, { ReactNode, useEffect, useState } from "react";

interface OffcanvasProps {
  id: string;
  children: ReactNode;
  show?: boolean; // Optional for controlled mode
  onHide?: () => void;
}

const Offcanvas: React.FC<OffcanvasProps> = ({
  id,
  children,
  show,
  onHide,
}) => {
  const [isVisible, setIsVisible] = useState(false); // Internal state

  useEffect(() => {
    if (show !== undefined) {
      setIsVisible(show); // Sync with external state if controlled
    }
  }, [show]);

  useEffect(() => {
    console.log("Offcanvas visibility:", isVisible);
  }, [isVisible]);

  const handleClose = () => {
    setIsVisible(false); // Close internally
    if (onHide) onHide(); // Notify parent if needed
  };

  return (
    <>
      {/* Backdrop (only if visible) */}
      {isVisible && (
        <div
          className="offcanvas-backdrop fade show"
          onClick={handleClose}
        ></div>
      )}

      {/* Offcanvas */}
      <div
        className={`offcanvas offcanvas-bottom rounded-top-4 border-0 ${
          isVisible ? "show" : ""
        }`}
        tabIndex={-1}
        id={id}
        aria-labelledby={`${id}Label`}
        style={{ display: isVisible ? "block" : "" }} // Auto-hide when needed
      >
        <div className="offcanvas-header d-flex justify-content-end align-items-center p-4 px-md-5 border-bottom">
          <button
            type="button"
            className="bg-transparent border-0 p-0 text-yellow d-flex align-items-center gap-2 text-uppercase"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={handleClose}
          >
            <span>
              <small>Close</small>
            </span>
            <X size={32} />
          </button>
        </div>
        <div className="offcanvas-body p-0">{children}</div>
      </div>
    </>
  );
};

export default Offcanvas;
