import { FunctionComponent, useCallback, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { Sword, Guitar, Person, BoxArrowUp, LockOpen } from "@phosphor-icons/react";

const QuickMenu: FunctionComponent = () => {
    const { authState } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    // State to track the selected menu item
    const [selected, setSelected] = useState<string>("");

    // Synchronize selected state with location.pathname
    useEffect(() => {
        switch (location.pathname) {
            case "/battles":
                setSelected("Battle Arena");
                break;
            case "/sign-in":
                setSelected("Log In");
                break;
            case "/my-dashboard":
                setSelected("My Dashboard");
                break;
            case "/my-videos":
                setSelected("Upload Video");
                setSelected("Become a Player");
                break;
            case "/find-opponent":
                setSelected("Find Opponent");
                break;
            default:
                setSelected(""); // Reset for other routes, including "/"
                break;
        }
    }, [location.pathname]);

    /** Action props */
    const onLearnSongsTextClick = useCallback(() => navigate("/learn-song-list"), [navigate]);
    const onBattlesTextClick = useCallback(() => navigate("/battles"), [navigate]);
    const onLogInTextClick = useCallback(() => navigate("/sign-in"), [navigate]);
    const onMyDashboardClick = useCallback(() => navigate("/my-dashboard"), [navigate]);
    const onMyVideosClick = useCallback(() => navigate("/my-videos"), [navigate]);
    const onFindOpponentClick = useCallback(() => navigate("/find-opponent"), [navigate]);

    const [menuItems, setMenuItems] = useState<string[]>([]);
    useEffect(() => {
        if (authState.isAuthenticated) {
            if (authState.user?.type === "user") {
                setMenuItems(["Battle Arena", "Find Opponent", "Upload Video"]);
            } else {
                setMenuItems(["Battle Arena", "Become a Player"]);
            }
        } else {
            setMenuItems(["Battle Arena", "Log In"]);
        }
    }, [authState.user?.type]);

    return (
        <div className="quickmenu">
            <div className="d-flex px-2 py-2 py-md-3">
                {menuItems.map((item, index) => (
                    <div className="flex-grow-1 w-100 text-center" key={index}>
                        <button
                            className={`border-0 bg-transparent p-0 ${selected === item ? "selected" : ""}`}
                            onClick={() => {
                                switch (item) {
                                    case "Battle Arena":
                                        onBattlesTextClick();
                                        break;
                                    case "Find Opponent":
                                        onFindOpponentClick();
                                        break;
                                    case "Upload Video":
                                        onMyVideosClick();
                                        break;
                                    case "Become a Player":
                                        onMyVideosClick();
                                        break;
                                    case "Log In":
                                        onLogInTextClick();
                                        break;
                                }
                            }}
                        >
                            {item === "Battle Arena" ? <Sword size={24} />
                                : item === "Find Opponent" ? <Person size={24} />
                                    : (item === "Upload Video" || item === "Become a Player") ? <BoxArrowUp size={24} />
                                        : <LockOpen size={24} />}
                            <p className="m-0 mt-1"><small>{item}</small></p>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuickMenu;