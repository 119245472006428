import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import {
  /*loginEmail  loginFacebook*/
  loginGoogle,
  loginPhone,
  validatePhone,
} from "../../api/login";
import { useAuth } from "../../providers/AuthProvider";
// import FacebookLogin, {
//   ProfileSuccessResponse,
// } from "@greatsumini/react-facebook-login";
import { logger as Logger, LogLevel } from "../../services/Logger";
import { Helmet } from "react-helmet-async";
import { Disable } from "react-disable";
import SignInWithPhoneForm from "./SignInWithPhoneForm";
import Offcanvas from "../components/Offcanvas";
import VerifyPhoneNumber from "./VerifyPhoneNumber";
import { isNullOrEmpty } from "../../utility/AppUtility";

interface SignInProps { }

const SignIn: FC<SignInProps> = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [userId, setUserId] = useState("");
  const [phone, setPhone] = useState("");
  const [showOffcanvasVerifyPhone, setShowOffcanvasVerifyPhone] =
    useState(false);

  // const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID as string;

  const handleGoogleLogin = async (code: string) => {
    try {
      setLoginInProgress(true);
      const { accessToken, refreshToken, user, lessons } = await loginGoogle(
        code
      );

      Logger.log(LogLevel.INFO, "Successful Google login:", {
        accessToken,
        refreshToken,
        user,
        lessons,
      });

      setLoginInProgress(false);
      // Use the login method from AuthProvider to update authentication state
      if (accessToken && user) {
        console.log("login " + user);
        login(user, {
          access_token: accessToken,
          refresh_token: refreshToken,
        });
        if (lessons && lessons.length > 0) {
          navigate("/home");
        } else {
          navigate("/home");
        }
      } else {
        Logger.error("Google login failed: Access token or user not available");
      }
    } catch (error: any) {
      setLoginInProgress(false);
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const errorMessage = (responseData as { message: string }).message;
        console.error("Request failed:", errorMessage);
        setError(errorMessage);
      } else {
        console.error("Sign up failed:", error);
      }
    }
  };

  const onClickLoginGoogle = useGoogleLogin({
    onError: (error) => {
      Logger.error("Google onError:", error);
    },
    onSuccess: (codeResponse) => {
      // console.log(codeResponse);
      handleGoogleLogin(codeResponse.code);
    },
    flow: "auth-code",
    scope: "openid email profile",
    // ux_mode: "redirect",
    // redirect_uri: `${window.location.origin}/sign-in`,
  });

  // const onClickFacebook = async (response: ProfileSuccessResponse) => {
  //   try {
  //     const { accessToken, refreshToken, user } = await loginFacebook(response);

  //     if (accessToken && user) {
  //       login(user, { access_token: accessToken, refresh_token: refreshToken });
  //       navigate("/my-lessons");
  //     } else {
  //       Logger.error(
  //         "Facebook login failed: Access token or user not available"
  //       );
  //     }
  //   } catch (error) {
  //     Logger.error("Facebook login failed:", error);
  //   }
  // };

  // const onClickSignInWithEmail = async (data: {
  //   email: string;
  //   password: string;
  // }) => {
  //   try {
  //     setLoginInProgress(true);
  //     const { accessToken, user, lessons } = await loginEmail(
  //       data.email,
  //       data.password
  //     );
  //     login(user, { access_token: accessToken, refresh_token: "" });
  //     setLoginInProgress(false);
  //     if (lessons && lessons.length > 0) {
  //       navigate("/home");
  //     } else {
  //       navigate("/home");
  //     }
  //   } catch (error) {
  //     setLoginInProgress(false);
  //     Logger.error("Email sign in failed:", error);
  //     setLoginFailed(true);
  //   }
  // };

  const onClickSignInWithPhone = async (data: { phone: string }) => {
    try {
      setLoginInProgress(true);
      const response = await loginPhone(data.phone);
      setShowOffcanvasVerifyPhone(true);
      console.log(response);
      setUserId(response.userId);
      setPhone(response.phone);
      setLoginInProgress(false);
    } catch (error) {
      setLoginInProgress(false);
      Logger.error("Phone sign in failed:", error);
      setLoginFailed(true);
    }
  };

  const onClickVerifyPhone = async (verificationCode: string) => {
    if (isNullOrEmpty(verificationCode) || isNullOrEmpty(userId)) {
      return;
    }
    try {
      setLoginInProgress(true);
      const response = await validatePhone(verificationCode, userId);
      console.log(response);
      setLoginInProgress(false);
      if (response.accessToken && response.user) {
        login(response.user, {
          access_token: response.accessToken,
          refresh_token: "",
        });
        navigate("/home");
      } else {
        setLoginFailed(true);
        setError(
          `Could not verify phone number. Please try again. ${response.message}`
        );
        Logger.error(
          "Phone sign in failed: Access token or user not available"
        );
      }
    } catch (error: any) {
      setLoginInProgress(false);
      Logger.error("Phone sign in failed:", error);
      setError(
        `Could not verify phone number. Please try again. ${error.response.data.message}`
      );
      setLoginFailed(true);
    }
  };

  return (
    <div className="w-100">
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"Sign in"}</title>
      </Helmet>
      <Disable disabled={loginInProgress}>
        <div className="d-flex flex-column w-100">
          {loginInProgress && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Login in progress</span>
              </div>
            </div>
          )}
          <div className="d-flex flex-column">
            {error && <div style={{ color: "yellow" }}>{error}</div>}
            <>
              <div className="border-bottom p-4 p-md-5">
                <h1 className="m-0 fs-1 fw-semibold text-center">
                  Log in or sign up
                </h1>
                {loginFailed && (
                  <div className="text-warning text-center px-4 px-md-5 py-2 py-md-4">
                    <p className="mb-0">
                      <small>Login failed. Please try again.</small>
                    </p>
                  </div>
                )}
              </div>

              <div
                className="p-4 px-md-5 pb-0 mx-auto"
                style={{ maxWidth: "460px", width: "100%" }}
              >
                <SignInWithPhoneForm
                  continue={onClickSignInWithPhone}
                  clearErrorMessage={() => setLoginFailed(false)}
                />
              </div>
              <div
                className="p-4 px-md-5 pt-0 pb-3 mx-auto"
                style={{ maxWidth: "460px", width: "100%" }}
              >
                <div className="d-flex gap-4 align-items-center">
                  <div className="col">
                    <hr />
                  </div>
                  <div className="text-center">
                    <p className="m-0">or</p>
                  </div>
                  <div className="col">
                    <hr />
                  </div>
                </div>
                <button
                  className="btn bg-dark-light rounded-pill mx-auto mt-4 mb-3 px-2 py-2 d-flex gap-3 align-items-center justify-content-between w-100"
                  onClick={onClickLoginGoogle}
                >
                  <span>
                    <img
                      src="/images/icons/icon-google-color.svg"
                      alt=""
                      className=""
                    />
                  </span>
                  Continue with Google
                  <span style={{ width: "32px" }}>&nbsp;</span>
                </button>
                {/* <FacebookLogin
                        appId="6022926104498630"
                        fields="first_name,last_name,email,picture"
                        onSuccess={(response) => {
                          console.log("Login Success!", response);
                        }}
                        onFail={(error) => {
                          console.log("Login Failed!", error);
                        }}
                        onProfileSuccess={(response) => {
                          console.log("Get Profile Success!", response);
                          onClickFacebook(response);
                        }}
                        render={({ onClick, logout }) => (
                          <button className="btn bg-dark-light rounded-pill w-100 mx-auto mb-4 px-2 py-2 mt-0 d-flex gap-3 align-items-center justify-content-between w-100" onClick={onClick}>
                            <span>
                              <img src="/images/icons/icon-facebook-color.svg" alt="" className="" />
                            </span>
                            Continue with{" "} Facebook
                            <span style={{ width: "32px" }}>&nbsp;</span>
                          </button>
                        )}
                      /> */}
                {/* <button className="btn bg-dark-light rounded-pill w-100 mx-auto mb-4 px-2 py-2 mt-0 d-flex gap-3 align-items-center justify-content-between w-100">
                  <span>
                    <img src="/images/icons/icon-facebook-color.svg" alt="" className="" />
                  </span>
                  Continue with{" "} Facebook
                  <span style={{ width: "32px" }}>&nbsp;</span>
                </button> */}
              </div>
            </>
          </div>
        </div>
        <Offcanvas
          id="verify-phone"
          show={showOffcanvasVerifyPhone}
          onHide={() => {
            setShowOffcanvasVerifyPhone(false);
          }}
        >
          <VerifyPhoneNumber
            phone={phone}
            errorMessage={error}
            continue={onClickVerifyPhone}
          />
        </Offcanvas>
      </Disable>
    </div>
  );
};

export default SignIn;
