export const isNullOrEmpty = (
  value: string | number | null | undefined
): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "string") {
    return value.trim().length === 0;
  }

  if (typeof value === "number") {
    return isNaN(value);
  }

  return false;
};
