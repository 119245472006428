import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";

// Define your user type/interface
interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
  phone: string;
  isAdmin: boolean;
  nickname: string;
  type: string;
}

export interface Tokens {
  access_token: string | null;
  refresh_token: string | null;
}

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  tokens?: Tokens;
}

export interface AuthContextProps {
  authState: AuthState;
  login: (user: User, tokens: Tokens) => void;
  logout: () => void;
  updateUserInfo : (firstName: string, lastName: string, picture: string, phone_number: string, nickname: string) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const initialAuthState: AuthState = {
    isAuthenticated: false,
    user: null,
    tokens: {
      access_token: localStorage.getItem("accessToken") || null,
      refresh_token: localStorage.getItem("refreshToken") || null,
      // Other token properties as needed, initialized from localStorage
    },
  };
  const [authState, setAuthState] = useState<AuthState>(initialAuthState);

  // Use a ref to store the navigate function
  const navigateRef = useRef(useNavigate());

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      const storedUser = localStorage.getItem("user");
      const user: User | null = storedUser ? JSON.parse(storedUser) : null;

      setAuthState({
        isAuthenticated: true,
        user: user,
        tokens: {
          access_token: localStorage.getItem("accessToken") || null,
          refresh_token: localStorage.getItem("refreshToken") || null,
        },
      });
    }
  }, []);

  const login = (user: User, tokens: Tokens) => {
    // console.log("[Auth_PROVIDER] login");
    setAuthState({
      isAuthenticated: true,
      user,
      tokens,
    });
    if (tokens.access_token !== null) {
      localStorage.setItem("accessToken", tokens.access_token);
    }
    if (tokens.refresh_token !== null) {
      localStorage.setItem("refreshToken", tokens.refresh_token);
    }
    localStorage.setItem("user", JSON.stringify(user));
  };

  const logout = async () => {
    // console.log("[Aut_PROVIDER] logout");
    setAuthState({
      isAuthenticated: false,
      user: null,
      tokens: undefined,
    });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");

    navigateRef.current("/sign-in");
  };

  const updateUserInfo = (firstName: string, lastName: string, picture: string, phone_number: string, nickname: string) => {
    // console.log("[Auth_PROVIDER] updateUserInfo");
    const storedUser = localStorage.getItem("user");
    const user: User | null = storedUser ? JSON.parse(storedUser) : null;
    if (user) {
      user.firstName = firstName;
      user.lastName = lastName;
      user.picture = picture;
      user.phone = phone_number;
      user.nickname = nickname;
      setAuthState({
        ...authState,
        user: user,
      });
      localStorage.setItem("user", JSON.stringify(user));
    }
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout, updateUserInfo }}>
      {children}
    </AuthContext.Provider>
  );
};
// import React, { useEffect, createContext, useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";

// interface User {
//   id: string;
//   firstName: string;
//   lastName: string;
//   email: string;
//   picture: string;
//   phone: string;
//   isAdmin: boolean;
// }

// export interface Tokens {
//   access_token: string | null;
//   refresh_token: string | null;
// }

// export interface AuthState {
//   isAuthenticated: boolean;
//   user: User | null;
//   tokens: Tokens | null; // Include tokens in AuthState
// }

// export interface AuthContextProps {
//   authState: AuthState;
//   login: (user: User, tokens: Tokens) => void;
//   logout: () => void;
// }

// const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error("useAuth must be used within an AuthProvider");
//   }
//   return context;
// };

// interface AuthProviderProps {
//   children: React.ReactNode;
// }

// export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
//   const initialAuthState: AuthState = {
//     isAuthenticated: false,
//     user: null,
//     tokens: null, // Initialize tokens to null
//   };
//   const [authState, setAuthState] = useState<AuthState>(initialAuthState);

//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedTokens = localStorage.getItem("tokens");
//     const storedUser = localStorage.getItem("user");

//     if (storedTokens && storedUser) {
//       const tokens: Tokens = JSON.parse(storedTokens);
//       const user: User | null = JSON.parse(storedUser);

//       setAuthState({
//         isAuthenticated: true,
//         user,
//         tokens, // Store tokens in authState
//       });
//     }
//   }, []);

//   const login = (user: User, tokens: Tokens) => {
//     setAuthState({
//       isAuthenticated: true,
//       user,
//       tokens,
//     });

//     localStorage.setItem("tokens", JSON.stringify(tokens));
//     localStorage.setItem("user", JSON.stringify(user));
//   };

//   const logout = async () => {
//     setAuthState({
//       isAuthenticated: false,
//       user: null,
//       tokens: null,
//     });

//     localStorage.removeItem("tokens");
//     localStorage.removeItem("user");

//     navigate("/sign-in");
//   };

//   return (
//     <AuthContext.Provider value={{ authState, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
