import { FacebookLogo, XLogo } from '@phosphor-icons/react';
import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from 'react-share';

interface SocialShareProps {
  url: string;
  title: string;
}

const SocialShare: React.FC<SocialShareProps> = ({ url, title }) => {
  console.log(url, title);
  return (
    <div className="social-share">
      <div className="pt-4 d-flex gap-3 flex-warp justify-content-start">
        <FacebookShareButton url={url} hashtag="#GuitarBattleClub">
          <div className="d-block p-4 text-yellow ratio ratio-1x1">
            <FacebookLogo size={24} />
          </div>
        </FacebookShareButton>
        <TwitterShareButton url={url} title={title}>
          <div className="d-block p-4 text-yellow ratio ratio-1x1">
            <XLogo size={24} />
          </div>
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
